<template>
	<div class="p-10 w-full display-question-highlight">
		<!-- Actions - Filter -->
		<div class="flex justify-between">
			<h4 class="text-white">
				{{ $t('Active Poll') }}
			</h4>
		</div>

		<div class="display-box mt-4">
			<div class="h-full w-full flex items-center justify-center">
				<div>
					<!-- Title -->
					<div class="display-question-title text-center text-white" :style="customStyle">
						{{ voting.name }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
  components: {
  },
  props: {
    voting: {
      type: Object,
      required: true,
    },
    eventSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

    };
  },
  computed: {
    eventPollsSetting() {
      const eventSetting = this.eventSettings && this.eventSettings ? this.eventSettings : null;
      return eventSetting.polls;
    },
    fontSizeSettings() {
      return this.eventSettings && this.eventSettings.style && this.eventSettings.style.font_size ? this.eventSettings.style.font_size : 28;
    },
    customStyle() {
      if (this.fontSizeSettings === 28) return `font-size: ${this.fontSizeSettings}px; line-height: 36px;`;
      if (this.fontSizeSettings === 35) return `font-size: ${this.fontSizeSettings}px; line-height: 43px;`;
      if (this.fontSizeSettings === 42) return `font-size: ${this.fontSizeSettings}px; line-height: 50px;`;
      if (this.fontSizeSettings === 48) return `font-size: ${this.fontSizeSettings}px; line-height: 54px;`;
      if (this.fontSizeSettings === 60) return `font-size: ${this.fontSizeSettings}px; line-height: 68px;`;
      return `font-size: ${this.fontSizeSettings}px; line-height: 36px;`;
    },
  },
  methods: {
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
