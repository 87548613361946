<template>
	<div class="p-10 w-full relative">
		<!-- Actions - Filter -->
		<div v-if="!isControl" class="flex justify-between " :class="textFontColor">
			<div  :class="textFontColor" :style="customStyle">
			{{ $t('Q&A') }}
			</div>

			<!-- <div class="flex space-x-4"  v-if="!isCleanDisplay">
				<vs-dropdown
					vs-custom-content vs-trigger-click
					class="cursor-pointer" >
					<div class="flex items-center">
						<h4 :class="textFontColor">
							{{ getSelectedOrderByLabel }}
						</h4>
						<vs-icon class="text-xl" color="#FFF">keyboard_arrow_down</vs-icon>
					</div>

					<vs-dropdown-menu v-if="!isCleanDisplay" class="sender-user-dropdown">
						<vs-dropdown-item
							v-for="(option, index) in orderByOptions"
							:key="index"
							@click="onFetchQuestion(option)"
							>
							<div
								:class="`${option.id === orderBy ? 'font-bold' : ''}`"
								class="flex cursor-pointer items-center p-2" >
								<span :style="customStyle">{{ option.name }}</span>
							</div>
						</vs-dropdown-item>
					</vs-dropdown-menu>
				</vs-dropdown>
				<h4 class=" flex items-center space-x-1" :class="textFontColor">
					<div>{{ printTotalQuestion }}</div>
					<vs-icon class="text-xl" color="#FFF">chat_bubble_outline</vs-icon>
				</h4>
			</div> -->
		</div>

    <div :style="isLatestPinned ? questionListIfLatestPinnedStyle : isControl ? questionListIfControlStyle : ''">
      <transition-group name="fade" mode="out-in">
        <div
          v-for="(question, index) in limitedDisplayQuestion" :key="`q-${index}`"
          class="display-question-list mt-4">
          <!-- Item -->
            <display-question-item
              :eventSettings="eventSettings"
              :question="question"
              :is-show-total-like="true"
              :isModerated="isModerated"
              :eventLang="eventLang"
              :isCleanDisplay="isCleanDisplay"
              @onHighlight="onHighlight"
              @onArchived="onArchived"
            />
        </div>
      </transition-group>
    </div>
      <!-- display latest question pin the latest question -->
      <div v-if="isLatestPinned" :style="!isControl ? latestQuestionStyle : latestQuestionWithControlStyle">
        <div :class="textFontColor" :style="customStyle">
        {{ $t('Latest Question') }}
        <display-question-item
          :eventSettings="eventSettings"
          :question="latestQuestionData(displayQuestions)"
          :is-show-total-like="true"
          :isModerated="isModerated"
          :eventLang="eventLang"
          :isCleanDisplay="isCleanDisplay"
          @onHighlight="onHighlight"
          @onArchived="onArchived"
        />
      </div>
    </div>

    <!-- footer -->
    <div v-if="isControl" class="flex items-center" :style="footerControl">
      <!-- search -->
      <div class="relative w-full w-1/2 flex items-center ml-10">
        <div class="w-full flex items-center">
          <vs-input class="lg:w-1/5 w-1/2" icon="search" placeholder="Search" v-model="keyword"/>
            <!-- filter -->
            <div class="ml-2">
              <vs-dropdown
                vs-custom-content vs-trigger-click
                class="cursor-pointer" >
                <div class="flex items-center">
                <vs-button style="height: 38px; width: 38px" class="m-0 p-0">
                    <div class="flex items-center">
                      <svg class="w-8 pt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>filter-variant</title><path fill="currentColor" d="M6,13H18V11H6M3,6V8H21V6M10,18H14V16H10V18Z" /></svg>
                    </div>
                  </vs-button>
                </div>

                <vs-dropdown-menu v-if="!isCleanDisplay" class="sender-user-dropdown">
                  <vs-dropdown-item
                    v-for="(option, index) in orderByOptions"
                    :key="index"
                    @click="onFetchQuestion(option)"
                    >
                    <div
                      :class="`${option.id === orderBy ? 'font-bold' : ''}`"
                      class="flex cursor-pointer items-center p-2" >
                      <svg class="w-6 ml-2 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check</title><path :fill="option.name === getSelectedOrderByLabel ? 'currentColor' : 'white'" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" /></svg>
                      <span :style="customStyle" class="ml-4">{{ option.name }}</span>
                    </div>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </div>
      </div>

    </div>
	</div>
</template>

<script>
import DisplayQuestionItem from '@/components/events/display/DisplayQuestionItem.vue';
import questionApi from '@/api/question';
import { duplicateVar, delay } from '@/lib/helper';

export default {
  components: {
    DisplayQuestionItem,
  },
  props: {
    eventId: {
      type: Number,
      required: true,
    },
    eventLang: {
      type: String,
      required: false,
      default: 'en',
    },
    eventSettings: {
      type: Object,
      required: true,
    },
    event: {
      type: Object,
    },
    question: {
      type: Object,
    },
    questionLimit: {
      type: Number,
      default: 8,
    },
    isCleanDisplay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      displayQuestions: [],
      currentPage: 0,
      lastPage: 0,
      orderBy: 'recent',
      orderByOptions: [
        { id: 'recent', name: this.$t('Recent') },
        { id: 'top', name: this.$t('Popular') },
        { id: 'oldest', name: this.$t('Oldest') },
        { id: 'starred', name: this.$t('Starred') },
      ],
      totalQuestion: 0,
      keyword: '',
    };
  },
  created() {
    this.totalQuestion = this.question.total;
    const questions = this.question.data;
    this.displayQuestions = questions.slice(0, this.limit);
    this.currentPage = this.question.first_page;
    this.lastPage = this.question.last_page;
    // this.initSocket();
  },
  watch: {
    keyword() {
      delay(() => {
        const selectedOrderBy = this.orderByOptions.find(curr => curr.id === this.orderBy);
        this.onFetchQuestion(selectedOrderBy);
      }, 500);
    },
  },
  computed: {
    textFontColor() {
      return {
        'text-white': !this.isDarkFont,
        'text-black': this.isDarkFont,
      };
    },
    isDarkFont() {
      return this.eventSettings && this.eventSettings.style && this.eventSettings.style.is_dark_color;
    },
    getSelectedOrderByLabel() {
      let label = '';
      const selectedOrderBy = this.orderByOptions.find(curr => curr.id === this.orderBy);
      if (selectedOrderBy) label = selectedOrderBy.name;
      return label;
    },
    limitedDisplayQuestion() {
      return this.displayQuestions;
    },
    isModerated() {
      return this.eventSettings && this.eventSettings.question_answer && this.eventSettings.question_answer.is_moderated;
    },
    fontSizeSettings() {
      return this.eventSettings && this.eventSettings.style && this.eventSettings.style.font_size ? this.eventSettings.style.font_size : 28;
    },
    customStyle() {
      if (this.fontSizeSettings === 28) return `font-size: ${this.fontSizeSettings}px; line-height: 36px;`;
      if (this.fontSizeSettings === 35) return `font-size: ${this.fontSizeSettings}px; line-height: 43px;`;
      if (this.fontSizeSettings === 42) return `font-size: ${this.fontSizeSettings}px; line-height: 50px;`;
      if (this.fontSizeSettings === 48) return `font-size: ${this.fontSizeSettings}px; line-height: 54px;`;
      if (this.fontSizeSettings === 60) return `font-size: ${this.fontSizeSettings}px; line-height: 68px;`;
      return `font-size: ${this.fontSizeSettings}px; line-height: 36px;`;
    },
    printTotalQuestion() {
      const publishedQuestion = this.displayQuestions.filter(curr => curr.is_published && !curr.is_archived);
      return publishedQuestion.length;
    },
    eventBackground() {
      return this.eventSettings && this.eventSettings.style && this.eventSettings.style.background ? this.eventSettings.style.background : null;
    },
    isLatestPinned() {
      return this.eventSettings && this.eventSettings.question_answer && this.eventSettings.question_answer.is_order_by_latest;
    },
    questionListIfLatestPinnedStyle() {
      return 'overflow-y: auto; max-height: calc(70vh - 70px)';
    },
    questionListIfControlStyle() {
      return 'overflow-y: auto; max-height: calc(100vh - 120px)';
    },
    latestQuestionStyle() {
      let baseColor = this.event.base_color;
      if (this.eventBackground) baseColor = 'transparent';
      return `padding: 10px 32px 10px 32px; overflow-y: auto; width: 100%; height: 30vh; position: absolute; left: 0; bottom: 0;  background-color: ${baseColor}; border-top: solid 8px black;`;
    },
    latestQuestionWithControlStyle() {
      let baseColor = this.event.base_color;
      if (this.eventBackground) baseColor = 'transparent';
      return `padding: 10px 32px 80px 32px; overflow-y: auto; width: 100%; height: 30vh; position: absolute; left: 0; bottom: 0;  background-color: ${baseColor}; border-top: solid 8px black;`;
    },
    isControl() {
      return this.$route.name === 'EventDisplayControl';
    },
    footerControl() {
      return 'width: 100%; height: 80px; position: fixed; bottom: 0; left:0; background-color: #1a1a1a';
    },

  },
  sockets: {
    connect() {
      console.log('connected');
    },
    events_update(payload) { // to change update on sort questions setting via admin
      // console.log(payload);
      const eventSetting = JSON.parse(payload.settings);
      const sortType = eventSetting.question_answer.display_sort;
      const sortOption = { id: sortType };
      this.d(sortOption);
      // Object.assign(this.event, payload);
    },
    questions_add(q) {
      if (q && !q.parent_id) {
        this.displayQuestions.unshift(q);
      }
    },
    questions_update_bulk(questions) {
      questions.forEach((q) => {
        if (q && !q.parent_id) {
          const index = this.displayQuestions.findIndex(curr => curr.id === q.id);
          if (index !== -1) {
            this.checkQuestionArchived(q, index);
          } else {
            this.displayQuestions.unshift(q);
          }
        }
      });
    },
    questions_update(question) {
      if (question && !question.parent_id) {
        const index = this.displayQuestions.findIndex(curr => curr.id === question.id);
        if (index !== -1) {
          // if (this.displayQuestions[index].is_archived !== question.is_archived) {
          this.checkQuestionArchived(question, index);
          // }
        } else {
          this.displayQuestions.unshift(question);
        }
      }
    },
    questions_delete(question) {
      const index = this.displayQuestions.findIndex(curr => curr.id === question.id);
      if (index !== -1) {
        this.displayQuestions.splice(index, 1);
      }
    },
  },
  methods: {
    latestQuestionData(displayQuestions) {
      if (this.displayQuestions.length > 0) {
        const publishedQuestion = displayQuestions.filter(curr => curr.is_published && !curr.is_archived);
        const questions = duplicateVar(publishedQuestion);
        // Sort the array based on the 'created_at' property in descending order
        questions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        // Get the latest question (the first element in the sorted array)
        const latestQuestion = questions[0];
        return latestQuestion;
      }
      return false;
    },
    checkQuestionArchived(q, index) {
      if (q.is_archived) {
        this.displayQuestions.splice(index, 1);
      } else {
        Object.assign(this.displayQuestions[index], q);
      }
    },
    onFetchQuestion(option) {
      this.orderBy = option.id;
      const callback = (response) => {
        const items = response.data;
        this.displayQuestions = items;
      };
      const errorCallback = () => {
      };
      let orderBy = 'created_at';
      let sortBy = 'asc';

      if (option && option.id === 'recent') {
        orderBy = 'created_at';
        sortBy = 'desc';
      } else if (option && option.id === 'oldest') {
        orderBy = 'created_at';
        sortBy = 'asc';
      } else if (option && option.id === 'starred') {
        orderBy = 'created_at';
        sortBy = 'desc';
      } else {
        orderBy = 'likes';
        sortBy = 'desc';
      }
      const params = {
        page: 1,
        limit: this.questionLimit,
        is_archived: 0,
        order_by: orderBy,
        sort_by: sortBy,
      };
      if (option && option.id === 'starred') params.is_starred = 1;
      if (this.isModerated) {
        params.is_published = 1;
      }
      if (this.keyword) {
        params.keyword = this.keyword;
      }
      questionApi.list(this.eventId, params, callback, errorCallback);
    },
    onHighlight(item) {
      this.$emit('onHighlightActive', item);
    },
    onArchived(item) {
      this.$emit('onArchived', item);
      // const index = this.displayQuestions.findIndex(curr => curr.id === item.id);
      // if (index !== -1) {
      //   Object.assign(this.displayQuestions[index], item);
      // }
    },
    fetchMoreQuestions() {
      // Replace with actual data fetching logic
      return new Promise((resolve, reject) => {
        const callback = (response) => {
          const items = response.data;
          resolve(items);
        };
        const errorCallback = (err) => {
          reject(err);
        };
        if (this.currentPage <= this.lastPage) {
          this.currentPage += 1;
        }
        const params = {
          page: this.currentPage,
          limit: this.questionLimit,
          is_archived: 0,
        };
        if (this.isModerated) {
          params.is_published = 1;
        }
        questionApi.list(this.eventId, params, callback, errorCallback);
      });
    },
    loadMore($state) {
      // setTimeout(() => {
      if (this.currentPage <= this.lastPage) {
        this.fetchMoreQuestions().then((moreItems) => {
          this.displayQuestions.unshift(...moreItems);
          $state.loaded();
        });
      } else {
        $state.complete();
      }
      // }, 1000);
    },

  },
  mounted() {

  },
  destroyed() {
  },
};
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
