import client from '../lib/http-client';

const endpoint = '/votings';

export default {
  // Create
  create(eventId, params, cb, errorCb) {
    const url = `${endpoint}/${eventId}`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.post(url, params)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Get by id
  get(id, cb, errorCb) {
    const url = `${endpoint}/${id}`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Get List
  getList(eventId, params, cb, errorCb) {
    const url = `${endpoint}/${eventId}`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.get(url, { params })
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Update
  update(id, params, cb, errorCb) {
    const url = `${endpoint}/${id}`;
    client.put(url, params)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // duplicate
  duplicate(id, cb, errorCb) {
    const url = `${endpoint}/${id}/duplicate`;
    client.post(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // reset
  reset(id, cb, errorCb) {
    const url = `${endpoint}/${id}/reset`;
    client.post(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // Delete
  delete(id, cb, errorCb) {
    const url = `${endpoint}/${id}`;
    client.delete(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // Get active
  getActive(eventId, cb, errorCb) {
    const url = `${endpoint}/${eventId}/active`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Get active
  participantCheck(votingId, cb, errorCb) {
    const url = `${endpoint}/${votingId}/participantChecks`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Vote
  vote(votingId, params, cb, errorCb) {
    const url = `${endpoint}/${votingId}/vote`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.post(url, params)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Publish
  publish(id, cb, errorCb) {
    const url = `${endpoint}/${id}/publish`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.post(url)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Export Participants
  exportList(type, id, cb, errorCb) {
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    const url = `${endpoint}/${id}/export/${type}`;
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Delete
  resetAll(eventId, cb, errorCb) {
    const url = `${endpoint}/${eventId}/resetAll`;
    client.delete(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

};
