<template>
	<div>
		<div class="display-qr-details p-10">
			<!-- Logo -->
			<div>
        <img :src="eventLogo" alt="Kobla" class="pr-5 max-w-250 pl-2" v-if="eventLogo && eventLogo !== '@/assets/images/logo/blank.png'" />
        <img src="@/assets/images/logo/koblatil.png" alt="Kobla" class="pr-5 max-w-250 pl-2" v-if="!eventLogo" />
			</div>

			<!-- Headline -->
			<div class="mt-4 max-w-[100px]">
				<h4>{{ eventName }}</h4>
			</div>

			<!-- QR -->
			<div class="mt-20 text-center" @click="showDetails()" v-if="isQrEnabled">
				<!-- <img src="@/assets/images/example/qr.png" alt="Kobla" class="pr-5 w-full cursor-pointer" @click="showDetails()" /> -->
        <qrcode-vue :value="`${eventLinkcode}?is_interactive=1`" :size="200" level="H" />
			</div>

			<!-- More -->
			<div class="text-center mt-10 text-lg" v-if="isQrEnabled">
				<div>{{ $t('Join at') }}</div>
				<div class="font-bold">koblatil.com</div>
				<div class="text-4xl mt-2 display-event-code">#{{ eventCode }}</div>
			</div>
		</div>

		<!-- Modal -->
		<modal
			v-if="isShowDetails"
			class="modal-qr-details"
			size="medium-large"
			:hide-footer="true"
			:show-close="true"
			:isEnableKeyUp="true"
			@close="closeDetails()"
			>
			<div slot="header">
			</div>
			<div slot="body">
				<div class="flex w-full justify-between">
					<div class="w-full px-4" @click="showDetails()">
						<!-- <img src="@/assets/images/example/qr.png" alt="Kobla" class="pr-5 w-full cursor-pointer" @click="showDetails()" /> -->
            <qrcode-vue :value="`${eventLinkcode}?is_interactive=1`" :size="200" level="H" />
					</div>
					<div class="w-full relative ml-4">
						<!-- Logo -->
						<div>
							<img src="@/assets/images/logo/koblatil.png" alt="Kobla" class="pr-5 w-1/2"/>
						</div>

						<!-- Headline -->
						<div class="mt-4 max-w-[100px]">
							<h3>{{ eventName }}</h3>
						</div>

						<!-- More -->
						<div class="mt-10 text-lg display-popup-qr-more-details">
							<div>
								<h3><span>{{ $t('Join at') }}</span> <span class="font-bold">koblatil.com</span></h3>
							</div>
							<div class="text-3xl display-event-code">{{ eventCode }}</div>
						</div>
					</div>
				</div>
			</div>
		</modal>
	</div>
</template>

<script>
import Modal from '@/components/Modal.vue';
// import questionApi from '@/api/question';
import QrcodeVue from 'qrcode.vue';

export default {
  components: {
    Modal,
    QrcodeVue,
  },
  props: {
    event: {
      type: Object,
      default: null,
    },
    isQrEnabled: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      isShowDetails: false,
      value: 'https://app.koblatil.com/',
    };
  },
  computed: {
    eventCode() {
      return this.event && this.event.code ? this.event.code : '';
    },
    eventName() {
      return this.event && this.event.name ? this.event.name : '';
    },
    eventLinkcode() {
      return this.event && this.event.link_code ? this.event.link_code : 'https://app.koblatil.com/';
    },
    eventLogo() {
      return this.event && this.event.logo ? this.event.logo : null;
    },
  },
  methods: {
    showDetails() {
      this.isShowDetails = true;
    },
    closeDetails() {
      this.isShowDetails = false;
    },
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
