<template>
	<div class="p-10 w-full display-question-highlight" :style="{ backgroundColor: !isCleanDisplay && event && event.base_color ? event.base_color : 'transparent', 'background-image' : `url('${eventBackground}')` }"
>
		<!-- Actions - Filter -->
		<div class="flex justify-between">
			<!-- <h4 class="text-white">
				{{ $t('Highlighted Question') }}
			</h4> -->
		</div>

		<div :class="textFontColor" class="display-box display-box-hover mt-4">
			<div v-if="isViewQuestionHighlight" ref="contentHighlight" class="h-full w-full flex items-center justify-center">
				<div>
					<!-- Title -->
					<div class="display-question-title text-center text-white font-bold" :style="customStyle">
						{{ question.name }}
					</div>

					<!-- Sender -->
					<div class="flex space-x-4 items-center mt-10 justify-center">
						<vs-avatar v-if="question.participant.avatar_thumbnail && !isHideSenderName" size="40px" class="text-3xl" :src="question.participant.avatar_thumbnail"/>
						<vs-avatar size="40px" class="text-3xl" v-if="!question.participant.avatar_thumbnail && !isHideSenderName" :color="getColor(question.sender_name)" :text="getInitial(question.sender_name)"  />
						<div class="text-white font-bold" v-if="!isHideSenderName" :style="customStyle">{{ question.sender_name === 'Anonymous' ? $t('Anonymous') : question.sender_name }}</div>
					</div>
				</div>
			</div>

      <div v-if="!isViewQuestionHighlight"  class="h-full w-full flex items-center justify-center">
        <div>
          <!-- Title -->
          <div class="display-question-title text-center text-white font-bold" :style="customStyle">
            {{ $t('There is currently no active highlighted question at this moment') }}.
          </div>
        </div>
      </div>

			<!-- Actions -->
			<div class="display-actions" v-if="!isCleanDisplay && isViewQuestionHighlight">
				<feather-icon
					class="cursor-pointer text-white question-action-button"
					svgClasses='h-8 w-8'
					icon="ArrowDownIcon"
					@click.stop="unHighlight(question)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import questionApi from '@/api/question';
import {
  getFirstCharacter, generateColorByAlphabet,
} from '@/lib/helper';

export default {
  components: {
  },
  props: {
    question: {
      type: Object,
    },
    eventSettings: {
      type: Object,
      required: true,
      default: null,
    },
    event: {
      type: Object,
      required: true,
      default: null,
    },
    isCleanDisplay: {
      type: Boolean,
      default: false,
    },
    isQrEnabled: {
      type: Boolean,
      default: () => true,
    },
    isViewQuestionHighlight: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      // question: {
      //   title: 'How can we combat climate change and ensure a sustainable future for our planet in the face of growing environmental challenges?',
      //   user: {
      //     name: 'Maulana Yusup A',
      //   },
      // },
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    textFontColor() {
      return {
        'text-white': !this.isDarkFont,
        'text-black': this.isDarkFont,
      };
    },
    isDarkFont() {
      return this.eventSettings && this.eventSettings.style && this.eventSettings.style.is_dark_color;
    },
    isHideSenderName() {
      return this.eventSettings && this.eventSettings.style && this.eventSettings.style.is_hide_participant_name;
    },
    senderNameSingleChar() {
      return this.question && this.question.sender_name ? this.question.sender_name.charAt(0) : 'A';
    },
    fontSizeSettings() {
      return this.eventSettings && this.eventSettings.style && this.eventSettings.style.font_size ? this.eventSettings.style.font_size : 28;
    },
    customStyle() {
      if (this.fontSizeSettings === 28) return `font-size: ${this.fontSizeSettings}px; line-height: 36px;`;
      if (this.fontSizeSettings === 35) return `font-size: ${this.fontSizeSettings}px; line-height: 43px;`;
      if (this.fontSizeSettings === 42) return `font-size: ${this.fontSizeSettings}px; line-height: 50px;`;
      if (this.fontSizeSettings === 48) return `font-size: ${this.fontSizeSettings}px; line-height: 54px;`;
      if (this.fontSizeSettings === 60) return `font-size: ${this.fontSizeSettings}px; line-height: 68px;`;
      return `font-size: ${this.fontSizeSettings}px; line-height: 36px;`;
    },
    eventCode() {
      return this.event && this.event.code ? this.event.code : '';
    },
    eventName() {
      return this.event && this.event.name ? this.event.name : '';
    },
    eventLinkcode() {
      return this.event && this.event.link_code ? this.event.link_code : 'https://app.koblatil.com/';
    },
    eventLogo() {
      return this.event && this.event.logo ? this.event.logo : null;
    },
    eventBackground() {
      return this.eventSettings && this.eventSettings.style && this.eventSettings.style.background ? this.eventSettings.style.background : null;
    },
  },
  methods: {
    getInitial(str) {
      return getFirstCharacter(str);
    },
    getColor(str) {
      return generateColorByAlphabet(str);
    },
    unHighlight(q) {
      const callback = () => {
        this.$emit('onHighlightDeactive', q);
      };
      const errorCallback = () => {
      };
      questionApi.highlight(q.id, callback, errorCallback);
    },
    onResizePage() {
      window.onresize = () => {
        this.windowWidth = window.innerWidth;
        this.addPadding();
      };
      setTimeout(() => {
        this.addPadding();
      }, 200);
    },
    addPadding() {
      const content = this.$refs.contentHighlight;
      if (this.windowWidth > 1680) {
        content.style.paddingLeft = '10%';
        content.style.paddingRight = '10%';
      } else {
        content.style.paddingLeft = '5%';
        content.style.paddingRight = '5%';
      }
    },
  },
  mounted() {
    this.onResizePage();
    window.document.title = `Highlight - ${this.event.name}`;
  },
  created() {
  },
  destroyed() {
  },
  watch: {
  },
};
</script>
