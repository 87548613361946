<template>
	<div class="p-10 w-full display-question-highlight">
		<!-- Actions - Filter -->
		<div class="flex justify-between">
			<h4 class="text-white" :style="customStyle">
				{{ $t("Active Poll - Results") }}
			</h4>
      <span v-if="eventPollsSetting &&  eventPollsSetting.is_vote_counter_visible">
        <span class="text-white flex ">
          <h4 class="text-white mt-4"> {{ voting && voting.meta && voting.meta.total_voter ? voting.meta.total_voter : 0 }} </h4>
          <svg class="pt-4 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>account</title><path fill="#BFBFBF" d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" /></svg>
        </span>
      </span>
		</div>
    <!-- voting type of multiple_choice -->
		<div class="display-box-poll  mt-4 overflow-y-auto" v-if="voting.type === 'multiple_choice' && !betaType">
			<div class="h-full w-full flex">
				<div class="w-full">
					<!-- Title -->
					<div class="text-white" :style="customStyle">
						{{ voting.name }}
					</div>

					<!-- Options -->
					<div class="mt-10 space-y-2 w-full">
						<div
							class="flex justify-between poll-option-item p-2 items-center"
							v-for="(option, index) in voting.options" :key="index">
							<!-- Name -->
							<div class="flex space-x-3 text-white ">
								<div :style="customStyle">{{ index + 1 }}.</div>
								<div class="w-full " :style="customStyle">{{ option.name }}</div>
							</div>
							<!-- Result -->
              <div class="flex justify-between" :style="customStyle">
                <div class="text-white" :style="customStylePersentage" v-if="eventPollsSetting && !eventPollsSetting.is_show_result">
                  {{ getPollPercentage(voting.results, option.id, voting && voting.meta && voting.meta.total_votes ? voting.meta.total_votes : 0) }} %
                </div>
                <div class=" text-white" v-else>
                  {{ option.meta.total_votes }}
                </div>
              </div>
						</div>
					</div>
				</div>
			</div>
		</div>
    <!-- voting type of open_text -->
    <div class="display-box-poll mt-4 " v-if="voting.type === 'open_text'">
			<div class="h-full w-full flex">
        <vue-word-cloud
          style="width: 100%;"
          animation-easing="ease"
          :animation-overlap="5"
          :spacing="1"
          :words="calculateWorldCloud(voting)"
          :color="calculateProportionColor"
          font-family="Roboto"
        />
			</div>
		</div>
    <div class="display-box-poll  mt-4 overflow-y-auto" v-if="betaType === 'multiple_choice_beta'">
      <div class="h-full w-full flex">
        <vue-word-cloud
          style="width: 100%;"
          animation-easing="ease"
          :animation-overlap="5"
          :spacing="1"
          :words="calculatePollWc(voting)"
          :color="calculateProportionColor"
          font-family="Roboto"
        />
			</div>
		</div>
	</div>
</template>

<script>
import VueWordCloud from 'vuewordcloud';

export default {
  components: {
    [VueWordCloud.name]: VueWordCloud,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    eventSettings: {
      type: Object,
      required: true,
    },
    voting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      betaType: '',
    };
  },
  computed: {
    eventPollsSetting() {
      const eventSetting = this.eventSettings && this.eventSettings ? this.eventSettings : null;
      return eventSetting.polls;
    },
    fontSizeSettings() {
      return this.eventSettings && this.eventSettings.style && this.eventSettings.style.font_size ? this.eventSettings.style.font_size : 28;
    },
    customStyle() {
      if (this.fontSizeSettings === 28) return `font-size: ${this.fontSizeSettings}px; line-height: 36px;`;
      if (this.fontSizeSettings === 35) return `font-size: ${this.fontSizeSettings}px; line-height: 43px;`;
      if (this.fontSizeSettings === 42) return `font-size: ${this.fontSizeSettings}px; line-height: 50px;`;
      if (this.fontSizeSettings === 48) return `font-size: ${this.fontSizeSettings}px; line-height: 54px;`;
      if (this.fontSizeSettings === 60) return `font-size: ${this.fontSizeSettings}px; line-height: 68px;`;
      return `font-size: ${this.fontSizeSettings}px; line-height: 36px;`;
    },
    customStylePersentage() {
      console.log(this.fontSizeSettings);
      if (this.fontSizeSettings > 42) {
        return 'min-width: 120px';
      }
      return 'min-width: 80px';
    },
  },
  methods: {
    calculateProportionColor() {
      const colors = ['#FFFFFF', '#F7FF38', '#ACF5FF', '#83FFF1', '#DFFFA2', '#F4baFF'];
      const randomIndex = Math.floor(Math.random() * colors.length);
      return colors[randomIndex];
    },
    calculatePollWc(voting) {
      const answer = this.countMultiChoiceAnswer(voting.options, voting.results);
      return answer;
    },
    calculateWorldCloud(voting) {
      const answer = this.countAnswers(voting.results);
      return answer;
    },
    countMultiChoiceAnswer(options, results) {
      const voteCounts = {};
      results.forEach((result) => {
        const optionId = result.voting_option_id;
        if (voteCounts[optionId]) {
          voteCounts[optionId]++;
        } else {
          voteCounts[optionId] = 1;
        }
      });

      // Create the final array structure
      const finalArray = options.map((detail) => {
        const optionId = detail.id;
        const voteCount = voteCounts[optionId] || 0;
        return [detail.name, voteCount];
      });

      return finalArray;
    },
    countAnswers(data) {
      const wordCount = {};
      data.forEach((item) => {
        // Split the 'answer' string into words and count them
        item.answer.split(/\s+/).forEach((word) => {
        // Make the word lowercase to count 'Word' and 'word' as the same word
          word = word.toLowerCase();
          wordCount[word] = (wordCount[word] || 0) + 1;
        });
      });
      // Convert the word counts object to an array of [word, count] arrays
      const wordCounts = Object.entries(wordCount);
      console.log(wordCounts);
      return Object.entries(wordCount);
    },
    checkOption() {
    },
    getPollPercentage(results, pollId, total) {
      if (results && results.length > 0) {
        const filteredOptions = results.filter(option => option.voting_option_id === pollId);
        const totalVotes = total;
        const currentVote = filteredOptions.length;
        const percentage = (currentVote / totalVotes) * 100;
        if (percentage && !this.eventPollCounter) {
          return this.formatPercentage(percentage);
        } if (percentage && this.eventPollCounter) {
          return currentVote;
        }
        return 0;
      }
      return 0;
    },
    formatPercentage(percentage) {
      if (percentage % 1 !== 0) {
        return percentage.toFixed(1); // If there's a decimal, fix it to 2 decimal places
      }
      return Math.round(percentage); // If it's an integer, round it to the nearest whole number
    },
  },
  mounted() {
  },
  created() {
    this.betaType = this.$route.query.is_beta ? 'multiple_choice_beta' : '';
  },
  destroyed() {
  },
};
</script>
