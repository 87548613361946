<template>
  <transition name="fade" mode="out-in">
	<div v-if="question && !question.is_archived && isModeratedAndPublished">
		<div class="question-item" :class="{'display-question-item' : !isDarkFont,
      'display-question-item-dark': isDarkFont,
      'display-question-highlight': question && question.is_highlight && isDarkFont,
      'display-question-highlight-dark': question && question.is_highlight && !isDarkFont}">
			<!-- Question -->
			<div class="question-wrapper flex justify-between">
				<div class="flex justify-between">
					<div class="flex space-x-2">
						<div>
              <!-- <img src="@/assets/images/file-icons/default-img.jpg" alt="" v-if="question.sender_name === 'Anonymous'"> -->
							<vs-avatar v-if="question.participant.avatar_thumbnail && !isHideSenderName" size="40px" class="text-3xl mt-4" :src="question.participant.avatar_thumbnail"/>
							<vs-avatar size="40px" class="text-3xl mt-4" v-if="!question.participant.avatar_thumbnail && !isHideSenderName" :color="getColor(question.sender_name)" :text="getInitial(question.sender_name)" />
						</div>
						<div>
							<div class="font-bold" :class="textFontColor" v-if="!isHideSenderName" :style="customStyle">{{ question.sender_name === 'Anonymous' ? $t('Anonymous') : question.sender_name }}</div>
							<div class="question-item__created-time text-xl" v-if="!isHideSenderName" :class="{...textFontColor,'text-2xl': fontSizeSettings > 28}">{{ getCreatedQuestionTime(question.created_at) }}</div>
              <div :class="textFontColor" :style="customStyle">
                {{ question.name }}
              </div>
						</div>
					</div>
				</div>
        <!-- Actions -->
        <div class="" v-if="!isCleanDisplay">
          <div class="flex items-center">
            <div v-if="question && question.is_starred">
              <div class="flex space-x-2 items-start ">
              <!-- star icon -->
              <div class="flex items-center space-x-1 mr-3">
                  <feather-icon class="question-item__like-icon cursor-pointer" :class="textFontColor" svgClasses='h-5 w-5' icon="StarIcon" :isStarred="question.is_starred" />
              </div>
            </div>
            </div>
            <div class="flex space-x-2 items-start ">
              <!-- Total Like-->
              <div class="flex items-center space-x-1" v-if="isShowTotalLike">
              <div class="question-item__total-like text-xl" :class="{...textFontColor, 'text-2xl': fontSizeSettings > 28}">{{ question.likes }}</div>
                  <feather-icon class="question-item__like-icon cursor-pointer" :class="textFontColor" svgClasses='h-5 w-5' icon="ThumbsUpIcon" @click="likeToggle()" />
              </div>
            </div>
          </div>
        </div>

        <!-- control -->
        <div v-if="isControl" class="question-actions">
          <div class="mr-2">
            <feather-icon
              v-if="!isHighlight"
              class="cursor-pointer  question-action-button"
              :class="textFontColor"
              :style="controlStyle"
              :icon="!question.is_highlight ? 'ArrowUpIcon' : 'ArrowDownIcon'"
              @click.stop="markAsHighlight()"
            />
            <div v-else class="cursor-pointer question-action-button is-loading" :style="controlStyle"/>
          </div>
          <div class="mr-2">
            <feather-icon
              v-if="!isMarked"
              class="cursor-pointer  question-action-button"
              :class="textFontColor"
              :style="controlStyle"
              icon="CheckIcon"
              @click.stop="markAsClear()"
            />
            <div v-else class="cursor-pointer question-action-button is-loading" :style="controlStyle"/>
          </div>
          <div>
            <feather-icon
              v-if="!isStarred"
              class="cursor-pointer  question-action-button"
              :class="textFontColor"
              :style="controlStyle"
              icon="StarIcon"
              :isStarred="question.is_starred"
              @click.stop="markAsStarred()"
            />
            <div v-else class="cursor-pointer question-action-button is-loading" :style="controlStyle"/>
          </div>
        </div>
			</div>
		</div>
	</div>
  </Transition>
</template>

<script>
import questionApi from '@/api/question';
import {
  getFirstCharacter, generateColorByAlphabet,
} from '@/lib/helper';
import dayjs from 'dayjs';
import noLocale from '@/lib/locale/no';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.locale('en');
dayjs.extend(relativeTime);
export default {
  components: {
  },
  props: {
    question: {
      type: Object,
      default: () => null,
    },
    isShowTotalLike: {
      type: Boolean,
      default: () => false,
    },
    isShowAction: {
      type: Boolean,
      default: () => false,
    },
    isShowActionDetails: {
      type: Boolean,
      default: () => true,
    },
    isModerated: {
      type: Boolean,
      required: true,
    },
    eventLang: {
      type: String,
      required: false,
      default: 'en',
    },
    eventSettings: {
      type: Object,
      required: true,
    },
    isCleanDisplay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHighlight: false,
      isMarked: false,
      isStarred: false,
    };
  },
  computed: {
    textFontColor() {
      return {
        'text-white': !this.isDarkFont,
        'text-black': this.isDarkFont,
      };
    },
    isDarkFont() {
      return this.eventSettings && this.eventSettings.style && this.eventSettings.style.is_dark_color;
    },
    isHideSenderName() {
      return this.eventSettings && this.eventSettings.style && this.eventSettings.style.is_hide_participant_name;
    },
    isModeratedAndPublished() {
      return this.isModerated ? this.question.is_published : true;
    },
    fontSizeSettings() {
      return this.eventSettings && this.eventSettings.style && this.eventSettings.style.font_size ? this.eventSettings.style.font_size : 28;
    },
    customStyle() {
      if (this.fontSizeSettings === 28) return `font-size: ${this.fontSizeSettings}px; line-height: 36px;`;
      if (this.fontSizeSettings === 35) return `font-size: ${this.fontSizeSettings}px; line-height: 43px;`;
      if (this.fontSizeSettings === 42) return `font-size: ${this.fontSizeSettings}px; line-height: 50px;`;
      if (this.fontSizeSettings === 48) return `font-size: ${this.fontSizeSettings}px; line-height: 54px;`;
      if (this.fontSizeSettings === 60) return `font-size: ${this.fontSizeSettings}px; line-height: 68px;`;
      return `font-size: ${this.fontSizeSettings}px; line-height: 36px;`;
    },
    customStyleSecondary() {
      return `font-size: ${this.fontSizeSettings / 2}%`;
    },
    customStyleIcon() {
      return `width: ${this.fontSizeSettings / 2}%; height: ${this.fontSizeSettings / 2}%`;
    },
    isControl() {
      return this.$route.name === 'EventDisplayControl';
    },
    controlStyle() {
      return 'height: 80px; width: 80px; display: flex; justify-content: space-evenly;';
    },
  },
  methods: {
    getCreatedQuestionTime(dateTime) {
      console.log(this.eventLang);
      if (this.eventLang === 'no') dayjs.locale(noLocale);
      else dayjs.locale('en');

      return dayjs(dateTime || new Date()).fromNow();
    },
    getInitial(str) {
      return getFirstCharacter(str);
    },
    getColor(str) {
      return generateColorByAlphabet(str);
    },
    likeToggle() {
    },
    viewDetails(question) {
      this.$emit('viewSelected', question);
    },
    showWithdrawQuestion(question) {
      this.$emit('showWithdrawQuestion', question);
    },
    markAsHighlight() {
      this.isHighlight = true;
      const callback = (response) => {
        const item = response.data;
        this.$emit('onHighlight', item);
        this.isHighlight = false;
      };
      const errorCallback = () => {
        this.isHighlight = false;
      };
      questionApi.highlight(this.question.id, callback, errorCallback);
    },
    markAsClear() {
      this.isMarked = true;
      const callback = (response) => {
        const item = response.data;
        this.$emit('onArchived', item);
        this.isMarked = false;
      };
      const errorCallback = () => {
        this.isMarked = false;
      };
      questionApi.archived(this.question.id, callback, errorCallback);
    },
    markAsStarred() {
      this.isStarred = true;
      const callback = (response) => {
        const item = response.data;
        this.$emit('onStarred', item);
        this.isStarred = false;
      };
      const errorCallback = () => {
        this.isStarred = false;
      };
      questionApi.starred(this.question.id, callback, errorCallback);
    },
    markAsUnClear() {
      // eslint-disable-next-line no-unused-vars
      const callback = (response) => {
        // this.$emit('onArchived', item);
      };
      const errorCallback = () => {
      };
      const params = {
        is_archived: 0,
      };
      questionApi.update(this.question.id, params, callback, errorCallback);
    },
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
